import '@angular/localize/init';

import 'deviceatlas-clientside/deviceatlas-lite.min';
import 'intersection-observer';
import { polyfill } from 'smoothscroll-polyfill';
import 'web-animations-js';
import 'zone.js';

import { environment } from './environments/environment';
import './global-shim';

polyfill();

if (!environment.production) {
    Error['stackTraceLimit'] = Infinity;
}
